import {strArr} from '../types';


export const createHeaders = (fields:any[]) => {
    const displayNames = getDisplayNames(fields);
    return {
        displayNames
    };
};

function getDisplayNames(fields:any) {
    let keys:strArr = [];
    let displayNames:strArr = [];
    for (const field of fields) { keys = [...keys,...Object.keys(field)]; }
    for (let i = 0; i < keys.length; i++) {
        const name:string = fields[i][keys[i]].DisplayName;
        displayNames = [...displayNames, name];
    }
    return displayNames;
}
