// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;600;700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "* {\n  color: #333333;\n}\n\n.light {\n  font-style: normal;\n  font-weight: 300;\n}\n\n.regular {\n  font-style: normal;\n  font-weight: 400;\n}\n\n.semi__bold {\n  font-style: normal;\n  font-weight: 600;\n}\n\n.bold {\n  font-style: normal;\n  font-weight: 700;\n}\n\n.wrapper-status {\n  flex-wrap: nowrap;\n  display: flex;\n}", "",{"version":3,"sources":["webpack://./src/styles/fonts.scss"],"names":[],"mappings":"AAEA;EACG,cAAA;AAAH;;AAGA;EACG,kBAAA;EACA,gBAAA;AAAH;;AAGA;EACG,kBAAA;EACA,gBAAA;AAAH;;AAGA;EACG,kBAAA;EACA,gBAAA;AAAH;;AAGA;EACG,kBAAA;EACA,gBAAA;AAAH;;AAEA;EACE,iBAAA;EACA,aAAA;AACF","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;600;700&display=swap\");\n\n* {\n   color: #333333;\n}\n\n.light {\n   font-style: normal;\n   font-weight: 300;\n}\n\n.regular {\n   font-style: normal;\n   font-weight: 400;\n}\n\n.semi__bold {\n   font-style: normal;\n   font-weight: 600;\n}\n\n.bold {\n   font-style: normal;\n   font-weight: 700;\n}\n.wrapper-status{\n  flex-wrap: nowrap;\n  display: flex;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
