import React from 'react';
import {IIcons} from './types';

export const ChevronUp = ({width = 15, height = 10, color = '#757575'}: IIcons) => {
    return (
        <svg width={width} height={height} viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1.24875 5.91553L4.5 2.67136L7.75125 5.91553L8.75 4.91678L4.5 0.666777L0.25 4.91678L1.24875 5.91553Z"
                fill={color}/>
        </svg>
    );
};
