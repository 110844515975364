import React, {useState} from 'react';
import {colors} from '../../styles/styles';
import {ChevronDown, ChevronUp} from '../../assets/icons';

export const StatusItem = () => {
    const [isOpen, setOpen] = useState<boolean>(false);

    const handleSelectItem = () => { };

    return (
        <div className={'status'}>
            <div className={'status__header'}>
                <span className={'status__output'} style={{backgroundColor: colors.AttentionYellow}}>
                <span className={'status-circle'} style={{backgroundColor: '#F2AE00'}}/>
                    Черновик
                </span>
                <span className={'status__icon'} onClick={() => setOpen(!isOpen)}>
                    {isOpen ? <ChevronUp width={15} height={10} /> : <ChevronDown width={15} height={10} />}
                </span>
            </div>
            {isOpen ? (
                <div className={'status__list'}>
                    <span className={'status__item'} onClick={handleSelectItem}>Редактировать</span>
                    <span className={'status__item'} onClick={handleSelectItem}>В принятые</span>
                    <span className={'status__item'} onClick={handleSelectItem}>В черновики</span>
                    <span className={'status__item'} onClick={handleSelectItem}>Удалить</span>
                </div>
            ) : null}

        </div>
    );
};
