import React from 'react';
import { Button, Select } from '../../../../fields';
import { IOption } from '../../../../types';

interface IActionProps {
    handleSelect: (num: number) => void;
}

const options: IOption[] = [
    {name: 'Редактировать', value: 'Редактировать'},
    {name: 'В принятые', value: 'В принятые'},
    {name: 'В черновики', value: 'В черновики'},
    {name: 'Удалить', value: 'Удалить'},
];

export const ActionsButton = ({handleSelect}:IActionProps) => {
    return (
        <div className={'action-buttons'}>
            <Select placeholder={'Действия'} handleSelect={handleSelect} options={options} />
            <Button text={'Применить'} onClick={() => null} />
        </div>
    );
};
