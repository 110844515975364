import {Navbar, Nav, NavItem, Button, Container, Row, Col, Breadcrumb, Table, Input} from 'react-bootstrap';
import { Link } from "react-router-dom";

import React, {Component} from 'react';
export  function TestTable(){
  return (
    <>
    <Table striped bordered hover>
    <thead>
      <tr>
        <th><input type = 'checkbox' /> </th>
        <th>ID</th>
        <th>Исследование</th>
        <th>Врач</th>
        <th>Код пациента</th>
        <th>Изменено</th>
        <th>Статус</th>
        <th>AP</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td><input type = 'checkbox' /> </td>
        <td>Multispect</td>
        <td>Просекин И.Б.</td>
        <td>23</td>
        <td>time_placeholder</td>
        <td>status</td>
        <td>AP</td>
      </tr>
      <tr>
        <td><input type = 'checkbox' /> </td>
        <td>Multispect</td>
        <td>Просекин И.Б.</td>
        <td>23</td>
        <td>time_placeholder</td>
        <td>status</td>
        <td>AP</td>
      </tr>
      <tr>
        <td><input type = 'checkbox' /> </td>
        <td>Multispect</td>
        <td>Просекин И.Б.</td>
        <td>23</td>
        <td>time_placeholder</td>
        <td>status</td>
        <td>AP</td>
      </tr>
    </tbody>
    </Table>
  </>
)
}
