// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_mh-userAvatar__5urdS {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  color: #ffffff;\n  font-weight: 700;\n  border-radius: 1000px;\n  overflow: hidden;\n}\n.style_mh-userAvatar__5urdS img {\n  width: 100%;\n  height: 100%;\n  object-fit: cover;\n}", "",{"version":3,"sources":["webpack://./src/components/avatar/style.module.scss"],"names":[],"mappings":"AACI;EACI,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,cAAA;EACA,gBAAA;EACA,qBAAA;EACA,gBAAA;AAAR;AAEQ;EACI,WAAA;EACA,YAAA;EACA,iBAAA;AAAZ","sourcesContent":[".mh- {\n    &userAvatar {\n        display: flex;\n        align-items: center;\n        justify-content: center;\n        color: #ffffff;\n        font-weight: 700;\n        border-radius: 1000px;\n        overflow: hidden;\n\n        img {\n            width: 100%;\n            height: 100%;\n            object-fit: cover;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mh-userAvatar": "style_mh-userAvatar__5urdS"
};
export default ___CSS_LOADER_EXPORT___;
