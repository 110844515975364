import { useState, type FC } from "react";
import { Form } from "react-bootstrap";

interface ICommentaryBlockProps {}

export const CommentaryBlock: FC<ICommentaryBlockProps> = ({}) => {
  const [checked, setChecked] = useState(false);

  return (
    <div className="p-3">
      <Form.Check
        type="checkbox"
        label="Комментарий"
        checked={checked}
        onChange={(e) => setChecked(e.target.checked)}
      />
      {checked && (
        <Form.Group className="mt-3">
          <Form.Label>Комментарий к визиту</Form.Label>
          <Form.Control as="textarea" rows={3} />
        </Form.Group>
      )}
    </div>
  );
};
